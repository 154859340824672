import { connect } from 'react-redux'
import authSelectors from '../redux/auth/authSelectors'
import React, { useRef, useEffect, useState } from 'react'
import { PostsList, SignUpModal } from '../components'
import PlusIcon from '../assets/img/plus.svg'
import { useHistory } from 'react-router-dom'
import routes from '../routes'
import axios from 'axios'
import { api_url } from '../config'
import ClipLoader from 'react-spinners/ClipLoader'
import authOperations from '../redux/auth/authOperations'
import { MetaTags } from 'react-meta-tags'
import logo from '../assets/img/logo.png'
import StreamSlider from '../components/StreamSlider'
import { Auth } from 'aws-amplify'

function Home({
  isLoggedIn,
  userId,
  redirectUrl,
  clearPrevUrl,
  status,
  createdDate,
}) {
  const [buttonIsVisible, setButtonIsVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line
  const [searchValue, setSearchValue] = useState('my/subscription')
  const [signUpModalIsOpen, setSignUpModalIsOpen] = useState(false)
  const [postsNew, setPostsNew] = useState([])
  const [currentPagePostsNew, setCurrentPagePostsNew] = useState(1)
  const [totalPagePosts, setTotalPagePosts] = useState(null)
  const [recomended, setRecomended] = useState([])
  const [advertisements, setAdvertisements] = useState([])

  const history = useHistory()
  // const location = useLocation();
  const createButton = useRef()
  const setToken = async () => {
    const session = await Auth.currentSession()
    // token.set(session.getIdToken().getJwtToken())
    return `Bearer ${session.getIdToken().getJwtToken()}`
  }
  useEffect(() => {
    if (!isLoggedIn && !signUpModalIsOpen) {
      const interval = setInterval(() => {
        setSignUpModalIsOpen(true)
        clearInterval(interval)
      }, 1000 * 45)
    }

    // eslint-disable-next-line
  }, [isLoggedIn, signUpModalIsOpen])
  useEffect(() => {
    if (redirectUrl) {
      history.push(redirectUrl)
      clearPrevUrl()
    }
  }, [redirectUrl, history])

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/')
      clearPrevUrl()
      window.localStorage.clear()
    }
  }, [isLoggedIn])
  const getRecomendedPosts = async () => {
    setLoading(true)
    const token = isLoggedIn ? await setToken() : ''
    axios
      .get(`${api_url}/feed/albums/recommended`, {
        headers: { Authorization: token },
      })
      .then((response) => setRecomended(response.data.data || []))
      .catch((err) => console.log(err.response))
      .finally(() => setLoading(false))
  }

  const getAdvertisements = async () => {
    setLoading(true)
    const token = isLoggedIn ? await setToken() : ''
    axios
      .post(
        `${api_url}/advertisements`,
        {
          limit: '100',
        },
        {
          headers: { Authorization: token },
        },
      )
      .then((response) => setAdvertisements(response.data.data || []))
      .catch((err) => console.log(err.response))
      .finally(() => setLoading(false))
  }

  const getPosts = async (page = 1) => {
    setLoading(true)
    const token = isLoggedIn ? await setToken() : ''
    axios
      .get(
        `${api_url}/feed/${
          isLoggedIn ? searchValue : 'last'
        }/albums?page=${page}`,
        {
          headers: { Authorization: token },
        },
      )
      .then((res) => {
        setCurrentPagePostsNew(res?.data?.albums?.current_page)
        setTotalPagePosts(res?.data?.albums?.last_page)
        setPostsNew(
          postsNew?.length
            ? [...postsNew, ...res?.data?.albums?.data]
            : res?.data?.albums?.data,
        )
      })
      .catch((err) => console.log(err.response))
      .finally(() => setLoading(false))
  }

  // eslint-disable-next-line
  const handleScroll = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (window.innerHeight + e.target.documentElement.scrollTop) <
        100 &&
      currentPagePostsNew + 1 < totalPagePosts &&
      !loading
    ) {
      getPosts(currentPagePostsNew + 1)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    setCurrentPagePostsNew(1)
    setTotalPagePosts(null)
    setRecomended([])
    setAdvertisements([])
    getRecomendedPosts()
    getPosts(1)
    if (isLoggedIn) {
      setButtonIsVisible(true)
      getAdvertisements()
    } else {
      setButtonIsVisible(false)
    }
    // eslint-disable-next-line
  }, [isLoggedIn])

  const getPostsList = () => {
    if (!advertisements?.length) {
      return postsNew
    } else if (postsNew?.length) {
      const resault = postsNew.slice()
      let index
      for (let count = 0; count < currentPagePostsNew; count++) {
        if (count - advertisements.length < 0) {
          index = count
        } else {
          for (let s = 0; s < count; s++) {
            if (count - advertisements.length * s > 0)
              index = Math.abs(count - advertisements.length * s)
          }

          index = index - advertisements.length < 0 ? index : 0
        }

        resault.splice(count + 10 * count, 0, advertisements[index])
      }
      return resault
    }
  }

  return (
    <>
      <MetaTags>
        <meta property="og:title" content="Testigram" />
        <meta property="og:image" content={logo} />
        <meta property="og:description" content="Share your testimonies" />
      </MetaTags>
      {isLoggedIn && <StreamSlider />}
      <div className="container home__container">
        <div className="wrap home__wrap">
          {recomended && <PostsList posts={recomended} userId={userId} />}

          {getPostsList() && (
            <PostsList posts={getPostsList()} userId={userId} />
          )}

          <ClipLoader color={'#050138'} loading={loading} size={75} />

          {!loading && status !== 2 && status !== 4 && (
            <div
              className={`home__button ${buttonIsVisible ? 'show' : 'hide'}`}
            >
              <button
                // className={`home__button`}
                ref={createButton}
                onClick={() => history.push(routes.chooseCategory)}
              >
                <img src={PlusIcon} alt="plus" />
              </button>
              <span className="home__button-text">Make a post</span>
            </div>
          )}
        </div>
      </div>
      <SignUpModal
        modalIsOpen={signUpModalIsOpen}
        onCloseModal={() => setSignUpModalIsOpen(false)}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: authSelectors.isLoggedIn(state),
    userId: authSelectors.getId(state),
    redirectUrl: authSelectors.getRedirectUrl(state),
    status: authSelectors.getUserStatus(state),
    createdDate: authSelectors.getUserCreatedDate(state),
  }
}

export default connect(mapStateToProps, {
  clearPrevUrl: authOperations.clearPrevUrl,
})(Home)
