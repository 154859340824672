import React from 'react'
import folderIcon from '../../assets/img/folder.svg'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import useInfiniteScroll from '../../utils/useInfiniteScroll'
import { Link, useHistory } from 'react-router-dom'
import routes from '../../routes'
import Avatar from 'react-avatar'
import likePost from '../../assets/img/liekPost.svg'

export default function SearchbarHint({
  nextPageUrl,
  loading,
  setSearchPage,
  searchBy,
  setSearchResults,
  setSearchBy,
  showHint,
  searchResults,
  id,
}) {
  const loadMore = () => !loading && setSearchPage((prevState) => prevState + 1)

  const [lastElementRef] = useInfiniteScroll(
    nextPageUrl ? loadMore : () => {},
    loading
  )

  const history = useHistory()

  const toggleTab = (key) => {
    if (searchBy === key) return
    setSearchBy(key)
    setSearchResults([])
    setSearchPage(1)
  }

  return (
    <div className={`searchbar__hint hint ${showHint && 'show'}`}>
      <div className='searchbar__tabs'>
        <button
          type='button'
          onClick={() => toggleTab('user')}
          className={`text text--bold searchbar__tab ${
            searchBy === 'user' && 'searchbar__tab--active'
          }`}
        >
          Users
        </button>
        <button
          type='button'
          onClick={() => toggleTab('category')}
          className={`text text--bold searchbar__tab ${
            searchBy === 'category' && 'searchbar__tab--active'
          }`}
        >
          Categories
        </button>
        <button
          type='button'
          onClick={() => toggleTab('post')}
          className={`text text--bold searchbar__tab ${
            searchBy === 'post' && 'searchbar__tab--active'
          }`}
        >
          Posts
        </button>
      </div>
      <div className={'scrollWrap'}>
        <Scrollbars style={{ width: 'auto', height: 300 }}>
          {searchResults.length > 0
            ? searchResults.map((element) => {
                return searchBy === 'user' ? (
                  <Link
                    className='followersModal__list-item searchbar__item'
                    key={element.id}
                    to={
                      element.id !== id
                        ? routes.userProfile + '/' + element.id
                        : routes.myProfile
                    }
                  >
                    <div className='follower' style={{ overflow: 'hidden' }}>
                      <Avatar
                        size={36}
                        name={element.fullName}
                        round='50%'
                        src={element?.media?.[0]?.url}
                        maxInitials={2}
                        color='#2E8299'
                      />
                      <div className='follower__info'>
                        <div
                          style={{
                            display: 'flex',
                            overflow: 'hidden',
                            alignItems: 'flex-start',
                          }}
                        >
                          <strong className='follower__info-username text text--small text--bold'>
                            {element.userName}
                          </strong>
                        </div>
                        <span className='follower__info-fullName text text--small'>
                          {element.fullName}
                        </span>
                      </div>
                    </div>
                  </Link>
                ) : searchBy === 'category' ? (
                  <button
                    onClick={() => {
                      history.push({
                        pathname: routes.feed + '/' + element.id,
                        state: {
                          categoryName: element.name,
                          categoryCount: element.albums_count,
                        },
                      })
                    }}
                    key={element.id}
                    className='hint__category'
                  >
                    <div className='hint__category-img'>
                      <img src={folderIcon} alt='folder' />
                    </div>
                    <div className='hint__category-info text text--normal'>
                      <span className='hint__category-name'>
                        {element.name}
                      </span>
                      <span className='hint__category-posts'>
                        {element.albums_count} posts
                      </span>
                    </div>
                  </button>
                ) : searchBy === 'post' ? (
                  <Link
                    className='followersModal__list-item searchbar__item'
                    key={element.id}
                    to={'/'}
                  >
                    <div className='follower' style={{ overflow: 'hidden' }}>
                      <Avatar
                        size={36}
                        round='10%'
                        name={element.name}
                        src={element.img}
                        maxInitials={2}
                        color='#2E8299'
                      />
                      <div className='follower__info'>
                        <div
                          style={{
                            display: 'flex',
                            overflow: 'hidden',
                            alignItems: 'flex-start',
                          }}
                        >
                          <strong className='text text--small text--bold'>
                            {element.title}
                          </strong>
                        </div>
                        <span className={'likeBlock'}>
                          <img src={likePost} alt='like post' />
                          {element.likes} likes
                        </span>
                      </div>
                    </div>
                  </Link>
                ) : null
              })
            : !loading && (
                <p
                  className='text text--bold text--center'
                  style={{ marginTop: '20px' }}
                >
                  No results
                </p>
              )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: '25px',
            }}
          >
            <ClipLoader color={'#050138'} loading={loading} size={25} />
          </div>
          <span ref={lastElementRef}></span>
        </Scrollbars>
      </div>
    </div>
  )
}
