import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { api_url } from '../config'
import { setToken } from '../getToken'
export default function AdvertisementRedirect({ data }) {
  const { search } = useLocation()
  const { id } = useParams()

  useEffect(() => {
    const advertisements = async () => {
      const token = await setToken()
      token &&
        axios
          .get(`${api_url}/advertisements/${id}`, {
            headers: { Authorization: token },
          })
          .finally(() => (window.location.href = search.slice(1)))
    }
    advertisements()
  }, [id, search])

  return (
    <div style={{ height: '100vh', textAlign: 'center', padding: '50px 0' }}>
      Redirect page
    </div>
  )
}
