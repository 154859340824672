import React, { useState, useEffect, useRef } from 'react'
import logo from '../../assets/img/logo.png'
import logoMedium from '../../assets/img/logo-768.png'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import authSelectors from '../../redux/auth/authSelectors'
import postSelectors from '../../redux/post/postSelectors'
import searchIcon from '../../assets/img/search-icon.svg'
import { ModalWithButtons } from '../../components'
import settingsIcon from '../../assets/img/settings-icon.svg'
import { Button, SettingsModal, ChangePassword } from '../../components'
import './header.scss'
import './headerMobile.scss'
import routes from '../../routes'
import Avatar from 'react-avatar'
import postOperations from '../../redux/post/postOperations'
import authOperations from '../../redux/auth/authOperations'
import axios from 'axios'
import { api_url } from '../../config'
import { setToken } from '../../getToken'
import SearchbarHint from './SearchbarHint'

function Header({
  isLoggedIn,
  fullName,
  avatar,
  onLogOut,
  id,
  stream,
  setStream,
}) {
  const [searchQuery, setSearchQuery] = useState('')
  const [currentRoute, setCurrentRoute] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [changePasswordOpen, setChangePasswordOpen] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchBy, setSearchBy] = useState('user')
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [searchPage, setSearchPage] = useState(1)
  const [showHint, setShowHint] = useState(false)
  const [finishModalIsOpen, setFinishModalIsOpen] = useState(false)
  const [avatarFinishModalIsOpen, setAvatarFinishModalIsOpen] = useState(false)
  const hintRef = useRef()

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath())
    if (!path.includes(hintRef.current)) {
      setShowHint(false)
      setSearchQuery('')
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick)
    return () => {
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    setCurrentRoute(location.pathname)
    handleCloseModal()
    setSearchQuery('')
    setShowHint(false)
  }, [location])

  const handleOpenModal = () => {
    modalIsOpen ? setModalIsOpen(false) : setModalIsOpen(true)
  }

  const handleCloseModal = (e) => {
    setModalIsOpen(false)
  }

  const handleChangePasswordOpen = () => {
    setChangePasswordOpen(true)
    handleCloseModal()
  }

  const handleChangePasswordClose = () => {
    setChangePasswordOpen(false)
  }
  const finishedStream = () => {
    setFinishModalIsOpen(true)
  }
  const avatarFinishedStream = () => {
    setAvatarFinishModalIsOpen(true)
  }
  const handleDeleteModalClose = () => {
    setFinishModalIsOpen(false)
    setAvatarFinishModalIsOpen(false)
  }
  const handleFinishStream = () => {
    setFinishModalIsOpen(false)
    axios
      .delete(`${api_url}/live/end/${stream.id}`)
      .then(() => {
        history.push('/')
        history.go(0)
      })
      .catch((err) => console.log(err))
  }
  const handleAvatarFinishStream = () => {
    setAvatarFinishModalIsOpen(false)
    axios
      .delete(`${api_url}/live/end/${stream.id}`)
      .then(() => {
        history.push(routes.myProfile)
        history.go(0)
      })
      .catch((err) => console.log(err))
  }
  const handleInputChange = (e) => {
    setSearchResults([])
    setSearchPage(1)
    setSearchQuery(e.target.value.trimStart())
    e.target.value.trimStart() ? setShowHint(true) : setShowHint(false)
    document.body.removeEventListener('click', handleOutsideClick)
  }
  // async function ionViewCanEnter() {
  //   try {
  //     await Auth.currentAuthenticatedUser()
  //     return true
  //   } catch {
  //     return false
  //   }
  // }
  // search
  useEffect(() => {
    const headerEffect = async () => {
      // setSearchResults([]);
      const token = await setToken()
      setLoading(true)
      if (searchQuery) {
        if (searchBy === 'user') {
          axios
            .post(`${api_url}/profile/search-users?page=${searchPage}`, {
              name: searchQuery,
              headers: { Authorization: token },
            })
            .then((response) => {
              if (searchResults.length > 0) {
                setSearchResults((prevState) => [
                  ...prevState,
                  ...response.data.users.data,
                ])
              } else {
                setSearchResults(response.data.users.data)
              }
              setLoading(false)
              setNextPageUrl(response.data.users.next_page_url)
            })
            .catch((err) => console.log(err))
        } else if (searchBy === 'category') {
          axios
            .post(`${api_url}/categories/name?page=${searchPage}`, {
              name: searchQuery,
              headers: { Authorization: token },
            })
            .then((response) => {
              if (searchResults.length > 0) {
                setSearchResults((prevState) => [
                  ...prevState,
                  ...response.data.categories.data,
                ])
              } else {
                setSearchResults(response.data.categories.data)
              }
              setLoading(false)
              setNextPageUrl(response.data.categories.next_page_url)
            })
            .catch((err) => console.log(err))
        } else if (searchBy === 'post') {
          // axios
          //     .post(`${api_url}/posts/name?page=${searchPage}`, {
          //       name: searchQuery,
          //     })
          //     .then((response) => {
          //       console.log(response)
          //       if (searchResults.length > 0) {
          //         setSearchResults((prevState) => [
          //           ...prevState,
          //           ...response.data.categories.data,
          //         ]);
          //       } else {
          //         setSearchResults(response.data.categories.data);
          //       }
          //       setLoading(false);
          //       setNextPageUrl(response.data.categories.next_page_url);
          //     })
          //     .catch((err) => console.log(err));
          setSearchResults([
            {
              id: 0,
              img: '',
              title: 'The great physician player travelled the distance',
              likes: '54',
              name: 'Tstat Hasdd',
            },
            {
              id: 1,
              img: '',
              title: 'To be a great player',
              likes: '47',
              name: 'Tstat Hasdd',
            },
            {
              id: 2,
              img: '',
              title: 'The great physician player travelled the distance',
              likes: '46',
              name: 'Tstat Hasdd',
            },
            {
              id: 3,
              img: '',
              title: 'The great physician player travelled the distance',
              likes: '32',
              name: 'Tstat Hasdd',
            },
            {
              id: 3,
              img: '',
              title: 'The great physician player travelled the distance',
              likes: '16',
              name: 'Tstat Hasdd',
            },
          ])
        }
      }
    }
    headerEffect()
  }, [searchBy, searchQuery, searchPage])
  return (
    <nav
      className={`navbar ${
        (isLoggedIn || currentRoute === routes.home) && 'navbar--dark'
      }`}
    >
      <div className="container navbar__container">
        {stream.isMyStream ? (
          <div
            onClick={finishedStream}
            style={{ cursor: 'pointer' }}
            className="logo navbar__logo"
          >
            <picture>
              <source media="(max-width:576px)" srcSet={logo} />
              <source media="(max-width:768px)" srcSet={logoMedium} />
              <img src={logo} alt="logo" />
            </picture>
          </div>
        ) : (
          <div
            onClick={() => {
              history.push('/')
              history.go(0)
            }}
            style={{ cursor: 'pointer' }}
            className="logo navbar__logo"
          >
            <picture>
              <source media="(max-width:576px)" srcSet={logo} />
              <source media="(max-width:768px)" srcSet={logoMedium} />
              <img src={logo} alt="logo" />
            </picture>
          </div>
        )}
        {isLoggedIn ? (
          <>
            <form
              ref={hintRef}
              className="searchbar header__searchbar"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="searchbar__button">
                <img src={searchIcon} alt="search" />
              </div>
              <input
                type="text"
                className="searchbar__input formInput"
                value={searchQuery}
                onChange={handleInputChange}
                placeholder="Search"
              />
              <SearchbarHint
                nextPageUrl={nextPageUrl}
                loading={loading}
                setSearchPage={setSearchPage}
                searchBy={searchBy}
                setSearchResults={setSearchResults}
                setSearchBy={setSearchBy}
                setSearchQuery={setSearchQuery}
                showHint={showHint}
                setShowHint={setShowHint}
                searchResults={searchResults}
                id={id}
              />
            </form>
            <div className="navbar__settings">
              {stream.isMyStream ? (
                <div
                  onClick={avatarFinishedStream}
                  style={{ cursor: 'pointer' }}
                  className="navbar__avatar"
                >
                  <Avatar
                    className="avatar"
                    // size={40}
                    name={fullName}
                    round="50%"
                    src={avatar}
                    maxInitials={2}
                    color="#2E8299"
                  />
                </div>
              ) : (
                <Link to={routes.myProfile} className="navbar__avatar">
                  <Avatar
                    className="avatar"
                    // size={40}
                    name={fullName}
                    round="50%"
                    src={avatar}
                    maxInitials={2}
                    color="#2E8299"
                  />
                </Link>
              )}
              <button type="button">
                <img
                  src={settingsIcon}
                  alt="settings"
                  onClick={handleOpenModal}
                />
                <SettingsModal
                  modalIsOpen={modalIsOpen}
                  onCloseModal={handleCloseModal}
                  onLogOut={onLogOut}
                  setChangePasswordOpen={handleChangePasswordOpen}
                />
              </button>
            </div>
          </>
        ) : (
          <div className="navbar__buttons">
            <Button
              onClick={() => {
                history.push(routes.register)
              }}
              className="button button--primary navbar__buttons-register"
              text="Create an account"
            />
            <Button
              contained={false}
              onClick={() => {
                history.push(routes.logIn)
              }}
              className="button button--outlined"
              text="Log in"
            />
          </div>
        )}
      </div>
      <ChangePassword
        modalIsOpen={changePasswordOpen}
        onCloseModal={handleChangePasswordClose}
      />
      {finishModalIsOpen && (
        <ModalWithButtons
          modalIsOpen={finishModalIsOpen}
          onCloseModal={handleDeleteModalClose}
          header="Finish stream"
          text="Are you sure you want to finish this stream?"
          onConfirm={handleFinishStream}
        />
      )}

      {avatarFinishModalIsOpen && (
        <ModalWithButtons
          modalIsOpen={avatarFinishModalIsOpen}
          onCloseModal={handleDeleteModalClose}
          header="Finish stream"
          text="Are you sure you want to finish this stream?"
          onConfirm={handleAvatarFinishStream}
        />
      )}
    </nav>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: authSelectors.isLoggedIn(state),
    fullName: authSelectors.getFullName(state),
    avatar: authSelectors.getAvatar(state),
    stream: postSelectors.getStream(state),
    id: authSelectors.getId(state),
  }
}

export default connect(mapStateToProps, {
  onLogOut: authOperations.logOut,
  setStream: postOperations.setStream,
})(Header)
